import React from 'react';
import styled from 'styled-components';
import { H1 } from '../../buttons/styledComponents';

const Container = styled.section`
  margin-bottom: 60px;
`;
const Title = styled(H1)`
  margin-bottom: 35px;
`;
const Wrapper = styled.div`
  width: 100%;
  position: relative;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #ffffff;
  border-left: solid 1px #c9cbce;
  border-top: solid 1px #c9cbce;
  border-bottom: solid 1px #c9cbce;
  &:after {
    z-index: -1;
    content: '';
    top: -1px;
    left: 100%;
    position: absolute;
    border-top: solid 1px #c9cbce;
    border-bottom: solid 1px #c9cbce;
    width: 100%;
    height: 100%;
    background-color: #fff;
    @media (max-width: 991px) {
      display: none;
    }
  }
  @media (max-width: 991px) {
    border-right: solid 1px #c9cbce;
    padding-right: 15px;
  }
`;
const Inner = styled.div`
  padding: 35px 0 35px 35px;
  @media (max-width: 991px) {
    padding: 15px;
  }
  li {
    font-size: 15px;
    line-height: 1.53;
    color: #000000;
  }
  .outer-list > li {
    position: relative;
    margin: 5px 0;
    padding-left: 24px;
    &:before {
      content: '';
      position: absolute;
      left: 0px;
      top: 7px;
      width: 7px;
      height: 7px;
      transform: rotate(-45deg);
      border-bottom: solid 1px #000;
      border-right: solid 1px #000;
    }
  }
  .inner-list {
    margin: 10px 0;
    & > li {
      padding-left: 26px;
      &:before {
        content: '— ';
        position: absolute;
        left: 28px;
      }
    }
  }
`;

const Capabilities = ({ data }) => {
  const { frontmatter, html } = data;
  return (
    <Container>
      <Title>{frontmatter.title}</Title>
      {html ? (
        <Wrapper>
          <Inner dangerouslySetInnerHTML={{ __html: html }} />
        </Wrapper>
      ) : null}
    </Container>
  );
};

export default Capabilities;
