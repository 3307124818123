import React from 'react';
import Helmet from 'react-helmet';

const Page = (props) => {
  const url = 'https://cursusts.com/';
  const metaDefaults = {
    'og:url': '',
    'og:type': 'website',
    'og:image': url + 'static/images/kursus.jpg',
    'og:image:width': 1200,
    'og:image:height': 627,
    'og:site_name': 'Российская компания Курсус',
    'og:title': 'Продукция российской компании Курсус',
    description:
      'Функциональные свойства продуктов Курсус позволяют подобрать индивидуальное решение под конкретные задачи заказчика с учётом особенностей и специфики каждого объекта и каждого элемента транспортной инфраструктуры на оборудуемой территории',
    keywords:
      'управление дорожным движением, средства управления дорожным движением, система управления дорожным движением, автоматизированная система управления дорожным движением, интеллектуальная система управления дорожным движением, платные автомагистрали, сбор платы за проезд по платным автомагистралям, системы управления парковкой, блок управления системой парковки, парковочный система, парковка авто, парковка город, парковка место, городской парковка, машина парковка',
  };
  metaDefaults['og:image:alt'] = metaDefaults['og:site_name'];
  metaDefaults['og:description'] = metaDefaults['description'];
  const meta = { ...metaDefaults, ...props.meta };
  meta['og:url'] = url + meta['og:url'];
  const title = props.title ? props.title : metaDefaults['og:site_name'];
  //console.log(meta);

  const metaArray = Object.keys(meta).map((key) => {
    const item = meta[key];
    const prop = key.indexOf('og:') !== -1 ? 'property' : 'name';
    return { [prop]: key, content: item };
  });

  //console.log('seo', metaArray, title)

  return <Helmet meta={metaArray} title={title}></Helmet>;
};

export default Page;
