import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

const ImgPlace = styled.div`
  width: 55px;
  height: 55px;
  margin-right: 12px;
  display: flex;
  align-items: center;
`;

const Tab = styled(Link)`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 255px;
  height: 72px;
  border-radius: 4px;
  padding: 20px 10px 20px 15px;
  background: #2e3339;
  font-size: 13px;
  font-weight: bold;
  color: ${(props) => (props.selected ? '#EA662F' : '#fff')};
  img {
    max-width: 100%;
    max-height: 40px;
  }
  &:nth-child(4) img {
    max-height: 34px;
  }
  &:after {
    display: ${(props) => (props.selected ? 'block' : 'none')};
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translate(-50%);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #2e3339;
    border-radius: 0px;
    @media (max-width: 991px) {
      bottom: -10px;
      display: none;
    }
  }
  ${(props) =>
    props.$short &&
    css`
      width: auto;
    `}
`;
const TabExt = styled.a`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 255px;
  height: 72px;
  border-radius: 4px;
  padding: 20px 10px 20px 15px;
  background: #2e3339;
  font-size: 13px;
  font-weight: bold;
  color: ${(props) => (props.selected ? '#EA662F' : '#fff')};
  margin-right: 10px;
  img {
    max-width: 100%;
    max-height: 40px;
  }
`;
const Tabs = styled.div`
  display: flex;
  @media (max-width: 991px) {
    display: block;
  }

  ${Tab} {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const Menu = (props) => {
  const { current } = props;
  const isCurrent = (name) => {
    return current === name;
  };
  return (
    <Tabs>
      <Tab selected={isCurrent('atlas')} to="/products/atlas">
        <ImgPlace>
          <img src="/static/images/products/product-atlas.svg" alt="Atlas" />
        </ImgPlace>
        АССУД “Атлас”
      </Tab>
      <Tab selected={isCurrent('sharptoll')} to="/products/sharptoll">
        <ImgPlace>
          <img src="/static/images/products/product-sharptoll.svg" alt="sharpToll" />
        </ImgPlace>
        СВП SharpToll
      </Tab>
      <TabExt selected={isCurrent('cleverpark')} href="http://cleverparking.ru/">
        <ImgPlace>
          <img src="/static/images/products/product-cleverpark.svg" alt="cleverPark" />
        </ImgPlace>
        CleverPark
      </TabExt>
      <Tab selected={isCurrent('aristotel')} to="/products/aristotel">
        <ImgPlace>
          <img src="/static/images/products/product-aristotel.svg" alt="aristotel" />
        </ImgPlace>
        Aristotel
      </Tab>
      <Tab selected={isCurrent('myboostr')} to="/products/myboostr">
        <ImgPlace>
          <img src="/static/images/products/product-bstr.svg" alt="bstr" />
        </ImgPlace>
        Экосистема BSTR
      </Tab>
      <Tab $short selected={isCurrent('parkomat')} to="/products/parkomat">
        Паркоматы
      </Tab>
    </Tabs>
  );
};

export default Menu;
