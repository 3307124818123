import React from 'react';
import styled from 'styled-components';
import { H1 } from '../../buttons/styledComponents';

const Container = styled.section`
  margin: 65px 0 65px;
  @media (max-width: 991px) {
    margin: 50px 0;
  }
`;
const Title = styled(H1)`
  margin-bottom: 35px;
`;
const Inner = styled.div`
  font-size: 15px;
  line-height: 1.53;
  color: #000;
  ul {
    padding-left: 55px;
  }
  li {
    display: flex;
    align-items: center;
    min-height: 50px;
    position: relative;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    &:before {
      content: '';
      position: absolute;
      left: -55px;
      background-image: url('/static/images/benefits-icon.png');
      width: 30px;
      height: 30px;
    }
  }
`;

const Benefits = ({ data }) => {
  const { frontmatter, html } = data;
  return (
    <Container>
      <Title>{frontmatter.title}</Title>
      <Inner dangerouslySetInnerHTML={{ __html: html }} />
    </Container>
  );
};

export default Benefits;
