import React from 'react';
import styled from 'styled-components';

const Inner = styled.section`
  margin-top: 47px;
  @media (max-width: 991px) {
    margin-top: 25px;
  }
  p {
    font-size: 16px;
    line-height: 1.56;
    color: #000;
  }
  img {
    /*min-height: 700px;*/
    display: block;
    margin: 40px auto 0 auto;
  }
  a.dark {
    display: inline-block;
    vertical-align: top;
    color: #000;
    text-decoration: none;
    line-height: 1.2;
    //font-size: .9em;
    //font-weight: bold;
    border-bottom: 1px dashed #aaa;
    transition: 0.2s all ease-in;
    margin: 15px 0 0;

    &:hover {
      text-decoration: none;
      border-bottom: 1px dashed #333;
    }
  }
`;

const Info = ({ data }) => {
  const { html } = data;

  return <Inner dangerouslySetInnerHTML={{ __html: html }} />;
};

export default Info;
